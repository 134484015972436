import {
	Field,
	DropdownMenu,
	DropdownItem,
  Cell,
	Icon,
	NumberKeyboard,
  Notify,
  Overlay,
  Popup,
  Picker,
  Search,
  RadioGroup,
  Radio
} from 'vant';
import { phoneBrand, getSystemAndModel } from '@/utils/utils.js';
import { areaList } from '@/utils/areaList.js';
import {
	uploadPassport,
  uploadCardFront,
  uploadCardBacd,
  getCountryList,
	idCardVerify,
  mergeVerify,
	idCardInit,
  mergeInit
} from '@/api/index.js';
import localStore from 'storejs';

export default {
	components: {
		[Field.name]: Field,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem,
		[Cell.name]: Cell,
		[Icon.name]: Icon,
		[NumberKeyboard.name]: NumberKeyboard,
		[Notify.name]: Notify,
		[Overlay.name]: Overlay,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Search.name]: Search,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
	},
	data() {
		return {
			isShowKeyboard: false, // 是否显示身份证号输入键盘
			certtypes: [
        { value: 1, text: 'Chinese ID (Resident Identity Card)' }, // 中华人民共和国居民身份证
        { value: 2, text: 'Mainland Permit (Hong Kong/Macau)' }, // 港澳居民来往内地通行证
        { value: 3, text: 'Mainland Permit (Taiwan)' }, // 台湾居民来往大陆通行证
        { value: 4, text: 'Passport' }, // 护照
      ],
      
      isShowModal: false,
      phoneType: 'IDPhoto', // IDPhoto: 证件照; aCard: 名片正面; bCard: 名片反面;
      isFirst : true, // 是否已显示过拍摄示范(只显示一次)
      uploadErrMsg: '', // 上传时的错误提示
      isShowTipsBox: false,  
      nodes: {
        idCard: true, // 是否显示实名认证
        trade: true, // 是否显示行业选择
        question: false, // 是否显示问卷调查
        card: true, // 是否显示名片上传
        userInfo: true, // 是否显示基本信息录入
      },
      css: {},
      options: [], // 基本信息 - 手动填写项列表
  
      worker: {
        workerTypes: [], // 身份类型列表
        showWorkerTypes: false,
        workerTypeName: '',
      },
  
      idCardObj: { // 实名认证校验属性
        isReUpload: false, // 是否需要重新上传
        isShowCertList: false, // 是否显示证件类型列表
        cartName: '', // 选中的证件名称
      },
      // h5QuestionEntity:{}, // 问卷调查
      
      cardObj: { // 名片校验属性
        frontchek: 0, // 名片正面是否通过
        backchek: 0, // 名片反面是否通过
        aCardPath: '', // 名片正面路径
        bCardPath: '', // 名片反面路径
      },
      info:{
        trade: '', // 所属行业
        trade_other: '',
        worker_type: '', // 身份类型
        name: '', // 姓名
        company: '', // 公司名称
        department: '', // 部门
        position: '', // 职位
        companyAdd: '', // 公司地址
        provinceId: '',
        cityId: '',
        countyId: '',
        provinceName: '', // 省
        cityName: '', // 市
        countyName: '', // 县/区
        area: '', // 地区
        email: '', // 邮箱
  
        id_card_name: '', // 证件姓名
        id_card_code: '', // 证件号码
        id_card_type: '', // 证件类型
        id_card_image_path: '', // 证件图片路径
  
        card_or_userinfo: 0, // 基本信息tab选中项: 1-card, 2-基本信息, 0-不显示“基本信息”节点
      },
      region: ['', '', ''], // 地区默认选中项
  
      isUpload: {
        front: false,
        back: false,
      },
  
      tradeList: [], // 行业属性列表
      isSubmit: false, // 是否提交中
      isReRealAuth: false, // 是否重新实名认证
      isShowReRealTips: false, // 是否在证件图右上角显示红色错误icon
     
      isShowAreaList: false,
      selected: '',
      areaList: areaList,
      keyword: '',
      isShowPicker: false,
      locationOptions: [],
      defaultIdx: 1,
      columns: [],
      allowInputOther: false,
		}
	},
	created() {
		if (this.tag === 'sms') { // 通过短信链接访问
      this.isReRealAuth = true;
      this.isShowReRealTips = true;
		}
    
    let flowId = localStore('flowId');
    
    this.getCountryList();
    if (this.isReRealAuth) { // 审核失败, 重新实名认证时
      this.getCardInfo();
    } else { // 正常流程的实名认证
      this.infoInit();
    }
    
    // Notify({ message: '通知内容', duration: 3000 });
	},
	mounted() {
		// 获取设备信息
		const device = getSystemAndModel();
		if (device.os === "iOS") { // ios系统
			// 不直接调用摄像头，自行选择获取模式
			this.$refs.imgFile.removeAttribute("capture");
		} else { // 安卓或其他系统
			if (phoneBrand() === 'xiaomi') { // 手机品牌为小米时, 从相册选择图片
				this.$refs.imgFile.setAttribute("accept", "image/*");
			} else {
				// 直接调用摄像头
				this.$refs.imgFile.setAttribute("capture", "camera");
			}
		}
		
	},
	methods: {
    // 根据国家名称在国家地区列表进行定位
    setDefaultIdx(provinceName) {
    	this.defaultIdx = this.columns.findIndex( e => e == provinceName);
    },
    // 显示国家列表
    showPicker() {
    	this.isShowPicker = true;
    	this.columns = this.locationOptions;
    	this.setDefaultIdx(this.info.provinceName);
    },
    // 国家列表确认选择
    onConfirm(value) {
    	this.info.provinceName = value;
    	this.isShowPicker = false;
    	this.keyword = '';
    },
    // 获取国家列表
    getCountryList() {
    	let countryList = localStore('countryList'); // 从本地存储取国家列表
    	if (countryList) {
    		this.handleData(countryList)
    	} else {
    		getCountryList().then(res => {
    			if(res.code == 200) {
    				localStore.set('countryList', res.data || []); // 本地存储取国家列表
    				this.handleData(res.data)
    			}
    			
    		}).catch(err => {
    			console.log(err);
    		});
    	}
    },
    // 国家列表处理
    handleData(arr = []) {
    	let columns = arr.map( e => e.area_name);
    	this.columns = columns;
    	this.locationOptions = Object.freeze(columns);
    },
    // 搜索符合条件的国家列表
    searchLocation() {
    	this.columns =  this.keyword ? this.locationOptions.filter( e => e.toLowerCase().includes(this.keyword.toLowerCase())) : this.locationOptions;
    },

		// 获取选择项的名称
		getCertType(val) {
      this.info.id_card_type = val;
			this.idCardObj.cartName = this.certtypes[val - 1].text;
			document.getElementById("fileElem").value = '';
			// this.info.id_card_image_path = '';
      this.$refs['dropdown-menu-item'].toggle();
		},
		// 获取状态数据(通过短信链接进入该页时)
		getCardInfo() {
			
			idCardInit(this.unionid).then(res => {
				let {checkState, idCardCode, idCardName, wxUrl, idCardType, idCardPath} = res.data;

				// 判断审核状态: 0-待审(进入待审页面); 1-审核通过(进入登记成功页面); 2-审核失败(进入实名校验页面 - 当前页面)
				if (checkState == 0) {
					this.$router.replace('/waiting_en');
				} else if (checkState == 1) {
					this.$router.replace('/memberCode_en');
				} else if (checkState == 2) {
					Notify({ message: res.data.idCardCheckReason || 'Please re-upload an image of your ID!', duration: 3000 });
					
					this.idCardObj.isReUpload = true;
          let certtype = this.certtypes.find(e => e.value == idCardType) || {};
					this.idCardObj.cartName = certtype.text || ''; // 证件名称
					
					this.info.id_card_name = idCardName || ''; // 证件姓名
					this.info.id_card_code = idCardCode || ''; // 证件号码
					this.info.id_card_type = idCardType || ''; // 证件类型
					this.info.id_card_image_path = idCardPath || ''; // 图片路径
				}
        
			})
		},
    infoInit(){
      let params = {
        browserType: 2, // 1-小程序, 2-H5
      }
      mergeInit(params).then(res => {
        // console.log(res);
        let { user = {}, nodes = {}, tradeList = [] } = res.data;
        
        tradeList.map(e => { // 所属行业列表
          if (e.hasOwnProperty('children') && !!e.children) {
            // 所属行业列表配置是否展开
            if (e.children.some(element => element.id === user.trade)) {
              e.isExpand = true;
            } else {
              e.isExpand = false;
            }

            e.children.map(s => {
              if (e.hasOwnProperty('children') && !!s.children) {
                // 子行业列表配置是否展开
                if (s.children.some(o => o.id === user.trade)) {
                  e.isExpand = true;
                  s.isExpand = true;
                } else {
                  s.isExpand = false;
                }
              }
              return s
            })
          }
          return e
        });
        this.tradeList = tradeList;
        // console.log(tradeList);

        let info = {
          // 实名认证
          id_card_name: user.id_card_name, // 证件姓名
          id_card_code: user.id_card_code, // 证件号码
          id_card_type: user.id_card_type, // 证件类型
          id_card_image_path: user.id_card_image_path, // 证件图片路径

          // 基本信息
          card_or_userinfo: user.card_or_userinfo || 0, // 基本信息tab选中项
          name: user.name || '',
          company: user.company || '',
          department: user.department || '',
          position: user.position || '',
          companyAdd: user.companyAdd || user.company_add || '',
          provinceId: user.provinceId || '',
          cityId: user.cityId || '',
          countyId: user.countyId || '',
          provinceName: user.provinceName || '',
          cityName: user.cityName || '',
          countyName: user.countyName || '',
          area: user.area || '',
          email: user.email || user.mailbox || user.mailbox2 || '',
          worker_type: user.worker_type, // 身份类型(适用于-主办单位合作方)
          
          // 所属行业
          trade: user.trade || '', 
          trade_other: user.trade_other || '', 
        };
        
        this.setDefaultIdx(user.countyName); // 根据国家名称在国家地区列表进行定位

        this.nodes = nodes;
        this.info = info; // 基本信息
        let certtype = this.certtypes.find(e => e.value == user.id_card_type) || {};
        this.idCardObj.cartName = certtype.text || ''; // 证件类型名称
        this.cardObj = {
          aCardPath: user.card_a_path, // 名片正面路径
          bCardPath: user.card_b_path, // 名片反面路径
          frontchek: user.card_a_verify || 0, // 名片正面是否通过(正面有信息校验，根据系统返还值判断)
          backchek: user.card_b_path ? 1 : 0, // 名片正面是否通过(校验是否存在路径: 反面没有信息校验，有图片就通过)
        };
        this.region = [user.provinceName || '', user.cityName || '', user.countyName || '']; // 地区栏默认值

      })
    },
    // 行业属性 - 收缩展开切换
    toggleTrade(id) {
      this.tradeList.map(e => {
        if (e.id == id) e.isExpand = !e.isExpand;
      })
    },
    toggleSubTrade(pid, id) {
      this.tradeList.map(e => {
        if (e.id == pid) {
          e.children.map(s => {
            if (s.id == id) s.isExpand = !s.isExpand;
            return s;
          })
        }
        return e;
      })
    },
    // 行业属性选中事件
    bindhyxz(id) {
      this.info.trade = id
      if (id == 99) { // 选中了其他时
        setTimeout( e => {
          this.allowInputOther = true;
        }, 200)
      }
    },
    onTabChange(active) {
      this.info.card_or_userinfo = active;
    },
    // 拍摄证件时
    shootCard() {
      this.phoneType = 'IDPhoto'; // IDPhoto: 证件照; aCard: 名片正面; bCard: 名片反面;
      this.shootStart();
    },
    // 当选择名片时
    onPhote(val) {
      this.phoneType = val; // IDPhoto: 证件照; aCard: 名片正面; bCard: 名片反面;

      if (this.isFirst) {
        this.isFirst = false;
        this.showModal();
      } else {
        this.shootStart();
      }
    },
    // 显示名片提示弹框
    showModal() {
      this.isShowModal = true
    },
    // 隐藏名片提示弹框
    hideModal() {
      this.isShowModal = false
    },
		// 触发拍摄
		shootStart() {
			setTimeout(() => {
				//触发点击事件	
				document.querySelector('#fileElem').click();
			}, 100)
		},
		//选择图片/拍摄照片
		chooseImg(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			this.imgPreview(files[0]);
		},
		// 预览图片
		imgPreview(file) {
			let that = this;
		
			if (file) {
				let reader = new FileReader();
				let image = new Image();
				
				// (File 或 Blob)读取完成时
				reader.onload = function(event) {
					
					// 图片加载完毕时
					image.onload = function() {
						
						// 设置默认宽高: 宽高比例 === 图片宽高比例
						let width = 750, height = width * (image.height / image.width);
						
						// 创建画布, 设置画布宽高
						let canvas = document.createElement("canvas");
						canvas.width = width;
						canvas.height = height;
		
						let ctx = canvas.getContext('2d');
						ctx.drawImage(image, 0, 0, width, height); // 绘图
						
						// 图片转base64, 参数: 图片类型(file.type), 图片质量
						let dataURI = canvas.toDataURL("image/jpeg", 1);
		
						that.hideModal();
            if (that.phoneType === 'IDPhoto') { // IDPhoto
              // that.info.id_card_image_path = dataURI; // 预览本地选中的图片
              that.uploadPassportImg(dataURI);
            } else if (that.phoneType === 'aCard') { // 名片正面
              // that.cardObj.aCardPath = dataURI;
              console.log(1);
              that.uploadCard(dataURI);
            } else if (that.phoneType === 'bCard') { // 名片反面
              // that.cardObj.bCardPath = dataURI;
              console.log(2);
              that.uploadCard(dataURI);
            }
            
					}
					
					image.src = event.target.result; // 加载图片: 将获取到的base64字符串赋值到图片路径
				}
			
				reader.readAsDataURL(file); // 进行(File 或 Blob)读取
			}
		},
		// 上传名片
		uploadCard(dataURI) {
		
			this.$toast.loading({
				forbidClick: true,
				duration: 0,
			});
			
      let base64 = dataURI.split(',')[1];
      let uploadCardAPI = this.phoneType === 'aCard' ? uploadCardFront : uploadCardBacd;
      uploadCardAPI(base64).then(res => {
        this.$toast.clear();
        this.cardObj.frontchek = true;
        
        // 预览上传接口返回的网络图片
        if(this.phoneType === 'aCard') {
          this.cardObj.aCardPath = res.data || '';
          this.cardObj.frontchek = true;
        } else {
          this.cardObj.bCardPath = res.data || ''
          this.cardObj.backchek = true;
        }
        
        document.getElementById("fileElem").value = '';
      }).catch(err => {
        this.$toast.clear();
        if(this.phoneType === 'aCard') {
          this.cardObj.frontchek = false;
        } else {
          this.cardObj.backchek = false;
        }
        document.getElementById("fileElem").value = '';
      });
		},
    // 上传证件照
    uploadPassportImg(dataURI) {
      this.$toast.loading({
      	forbidClick: true,
      	duration: 0,
      });
      
      let base64 = dataURI.split(',')[1];
      uploadPassport(base64, this.info.id_card_type).then(res => {
        // console.log(res);
      	this.$toast.clear();
      	let {idCardName, idCardCode, img} = res.data;
        if (this.info.id_card_type == 4) { // 回填护照图片
          this.isShowReRealTips = false,
          this.idCardObj.isReUpload = false,
          this.info.id_card_image_path = img; // 证件照
        } else { // 回填识别出的通行证信息
          this.idCardObj.isReUpload = false,
          this.info.id_card_image_path = img, // 证件照
          this.info.id_card_name = idCardName, // 证件姓名
          this.info.id_card_code = idCardCode; // 证件号码
      	}
      	document.getElementById("fileElem").value = '';
      }).catch(err => {
      	this.$toast.clear();
        Notify({ message: err.message, duration: 3000 });
      
      	document.getElementById("fileElem").value = '';
      });
    },
		nextStep() {
      let params = {}, submitAPI = null;

			// 实名认证校验
			if (this.nodes.idCard || this.isReRealAuth) {
			  if(!this.info.id_card_type) {
			    Notify({ message: 'Please select your ID type', duration: 3000 });
			    return
			  }
			  
			  if (this.info.id_card_type == 1) { // 身份证
			    if (!/^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,30}$))/.test(this.info.id_card_name)) {
			      Notify({ message: 'Please enter the correct name', duration: 3000 });
			      // this.$refs.idCardName.focus();
			      return
			    }
			    // 身份证号：15位时全为数字，18位时前17位为数字，最后一位是校验位，可能为数字或字符X
			    if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.info.id_card_code)) {
			      Notify({ message: 'Please enter the correct ID number', duration: 3000 });
			      return
			    }
			  } else if (this.info.id_card_type == 4) { // 护照: 不用校验证件姓名和证件号码
			    if (!this.info.id_card_image_path) {
			      Notify({ message: 'Please upload an image of your ID', duration: 3000 });
			      return
			    }
			    if (this.idCardObj.isReUpload) {
			      Notify({ message: 'Please re-upload an image of your ID', duration: 3000 });
			      return
			    }
          if (!this.info.id_card_name) {
            Notify({ message: 'Please Input ID Name', duration: 3000 });
            return
          }
          if (!this.info.id_card_code) {
            Notify({ message: 'Please Input ID Number', duration: 3000 });
            return
          }
			  } else { // 港澳台通行证
			    if (!this.info.id_card_name) {
			      Notify({ message: 'Please Input ID Name', duration: 3000 });
			      return
			    }
			    if (!this.info.id_card_code) {
			      Notify({ message: 'Please Input ID Number', duration: 3000 });
			      return
			    }
			    if(!this.info.id_card_image_path) {
			      Notify({ message: 'Please upload an image of your ID', duration: 3000 });
			      return
			    }
			    // 校验是否重新上传了图片
			    if (this.idCardObj.isReUpload) {
			      Notify({ message: 'Please re-upload an image of your ID', duration: 3000 });
			      return
			    }
			  }
			}
			
      if (this.isReRealAuth) {
        submitAPI = idCardVerify;
        params = {
          browserType: 2, // 1-小程序, 2-H5
          unionid: this.unionid,
          name: this.info.id_card_name, // 证件名
          idCard: this.info.id_card_code, // 证件号
          idCardPath: this.info.id_card_image_path, // 图片路径
          idCardType: this.info.id_card_type, // 证件类型
        };
      } else{
        
        submitAPI =  mergeVerify;
        params = {
          browserType: 2, // 1-小程序, 2-H5
          saveItemTrade: this.nodes.trade,
          saveItemUserInfo: this.info.card_or_userinfo == 2,
          ...this.info,
          
          question: 0,
        };
        
        // 基本信息校验
        if (this.info.card_or_userinfo) {
          if (this.info.card_or_userinfo == 1 && this.nodes.card) { // 拍名片校验
            if (!this.cardObj.frontchek) {
              Notify({ message: 'Business card photo (front)', duration: 3000 });
              return;
            }
            // if (!this.cardObj.backchek) {
            //   Notify({ message: 'Business card photo (back)', duration: 3000 });
            //   return;
            // }
            
            // 拍名片时, 移除下列提交字段
            delete params.name;
            delete params.company;
            delete params.department;
            delete params.position;
            delete params.provinceId;
            delete params.provinceName;
            delete params.cityId;
            delete params.cityName;
            delete params.countyId;
            delete params.countyName;
            delete params.area;
            delete params.companyAdd;
            delete params.email;
          }
          
          if (this.info.card_or_userinfo == 2 && this.nodes.userInfo) { // 手动填写校验
          
            for (let e of this.style.informationOptions) {
              
              // 校验必填项是否有内容
              if (e.isShow && e.isRequired && e.id !== 'area' && !this.info[e.id]) {
                Notify({ message: `${e.name} is required`, duration: 3000 });
                return
              }
              // 校验国家必填项是否有内容
              if (e.isShow && e.isRequired && e.id === 'area' && !this.info.provinceName) {
                Notify({ message: 'Country is required', duration: 3000 });
                return
              }
              // 校验城市必填项是否有内容
              if (e.isShow && e.isRequired && e.id === 'area' && !this.info.cityName) {
                Notify({ message: 'City is required', duration: 3000 });
                return
              }
              
              // 邮箱为必填项时，校验邮箱格式
              if (e.isShow && e.isRequired && e.id === 'mailbox' && !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.info.mailbox)) {
                Notify({ message: 'Wrong format of E-mail', duration: 3000 });
                return;
              }
            }
            
            // 最少字符限制：公司2、部门2、职位2、公司地址5; 英文版最少字符数翻倍
            if (this.info.company && this.info.company.length < 2) {
              Notify({ message: 'Company: Please enter at least 2 characters', duration: 3000 });
              return;
            }
            if (this.info.department && this.info.department.length < 2) {
              Notify({ message: 'Department: Please enter at least 2 characters', duration: 3000 });
              return;
            }
            if (this.info.position && this.info.position.length < 2) {
              Notify({ message: 'Position: Please enter at least 2 characters', duration: 3000 });
              return;
            }
            if (this.info.companyAdd && this.info.companyAdd.length < 5) {
              Notify({ message: 'Company Address: Please enter at least 5 characters', duration: 3000 });
              return;
            }
            
          }
        }
        
        // 校验所属行业
        if (this.nodes.trade) {
          if (!this.info.trade) {
            Notify({ message: 'Please select a profession', duration: 3000 });
            return
          }
          if (this.info.trade == 99 && !this.info.trade_other) {
            Notify({ message: 'Others cannot be empty', duration: 3000 });
            return false
          }
        }
        
      }
      // console.log(params); return;
		
			this.$toast.loading({
				loadingType: 'spinner',
				forbidClick: true,
				duration: 0,
			});
      
			submitAPI(params).then(res => {
        this.$toast.clear();
				this.$router.replace(res.data.h5Url)
			}).catch(err => {
				this.$toast.clear();
			});
      
		}
	}
}
